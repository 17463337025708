<template>
    <div>
        <!-- title -->
        <div class="container-fluid bg-area-of-expertise-header px-0 py-5 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 text-white text-center py-0 py-lg-5">
                                <h1 class="d-lg-block d-none font-size-area-of-expertise-header mp-rbk mp-font-weight-medium my-0 py-0">Alle Fachgebiete auf einen Blick</h1>
                                <h1 class="d-lg-none d-block font-size-area-of-expertise-header-mobile mp-rbk mp-font-weight-medium my-0 py-0">Alle Fachgebiete auf einen Blick</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /title -->

        <div class="container-fluid bg-lightblue py-5">
            <!--
            <div class="row">
                <div class="col-12">
                    <div class="container py-4">
            -->
                        <!-- category -->
                        <div class="row mb-5" v-for="c in categories" :key="`${keyPrefix}_${c.detail.id}`">
                            <div class="col-12">
                                <div class="container py-1 bg-white form-rounded-left form-rounded-right area-of-expertise-category">
                                    <div class="row mt-1 mt-md-4">
                                        <div class="col-12 col-md-5 col-lg-3 mb-4 d-none d-md-block">
                                            <span class="mp-rbk d-block aoe-title mb-3">{{ c.detail.title }}</span>
                                            <router-link :to="`/experten/${c.detail.title}/${c.detail.id}/${a.title}/${a.id}`" v-for="a in c.detail.areas" class="mp-rbk d-block aoe-item mb-3" :key="`${keyPrefix}_${c.detail.id}_${a.id}`">
                                                {{ a.title }}
                                            </router-link>
                                        </div>
                                        <div class="col-12 col-md-7 col-lg-9 text-center">
                                            <span class="mp-rbk d-block aoe-title mb-3 mt-4 d-block d-md-none">{{ c.detail.title }}</span>
                                            <div class="container-fluid px-0">
                                                <div class="row">
                                                    <!-- aoe-image -->
                                                    <div class="col-12 col-lg-4 mb-4" v-for="a in c.detail.areas" :key="`${keyPrefix}_${c.detail.id}_${a.id}_img`">
                                                        <router-link :to="`/experten/${c.detail.title}/${c.detail.id}/${a.title}/${a.id}`" replace>
                                                            <div
                                                                class="aoe-image position-relative d-flex align-items-center justify-content-center"
                                                                :data-content="a.title"
                                                            >
                                                                <img v-if="a.media" class="mw-100 form-rounded-left form-rounded-right" :src="a.media.url" :alt="a.title"/>
                                                                <img v-else class="form-rounded-left form-rounded-right" style="max-width: 120px;height: 152px" src="../../assets/img/mergeplate_logo.svg" :alt="a.title"/>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                    <!-- /aoe-image -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /category -->
            <!--
                    </div>
                </div>
            </div>
            -->
        </div>

        <!-- tag cloud -->
        <TagCloud class="d-none d-md-block"/>
        <!-- /tag cloud -->
    </div>
</template>



<script>
    import {mixinBasics} from "../../mixins/basics";
    import TagCloud from "../../components/TagCloud";

    export default {
        name: "Category",

        metaInfo() {
            return {
                title: 'Fachgebiete',

                meta: [
                    { name: 'Fachgebiete | mergeplate.com' },
                    { title: 'Fachgebiete | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    'Fachgebiete | mergeplate.com',
                    'Fachgebiete - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit',
                    this.$route.path
                ))
            };
        },

        components: {
            TagCloud
        },

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'cat',
            };
        },

        computed: {
            categories() {
                return this._categories
                    /*
                    .filter(c =>
                        // return only categories where experts r available
                        this._experts.filter(e => e.categories.find(ec => ec.id === c.id)).length > 0
                    )
                    */
                    .map(c => {
                        return {
                            'detail': c,
                            // get only areas who we have experts in
                            'areas': this.getAreas(c.id)
                        }
                    });
            }
        },

        methods: {
            getAreas(categoryId) {
                return this._categories.find(c => c.id === categoryId).areas.filter(a =>
                    this._experts.filter(e => e.areas.find(ea => ea.id === a.id)).length > 0
                );
            },
        },

        mounted() {
            window.scrollTo(0,0);
        }

    }
</script>



<style scoped>

</style>